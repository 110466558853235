<template>
  <transition name="menu">
    <form-laporan :is-edit="true"/>
  </transition>
</template>

<script>
import formLaporan from './components/formLaporan'

export default {
  name: 'EditFormLaporan',
  components: { formLaporan }
}
</script>

